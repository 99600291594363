<template>
  <div class="detail-input">
    <!-- <el-form-item class="detail-input-title"  :label="`${title}:`"></el-form-item> -->
    <el-form-item class="detail-input-title">{{ title }}：</el-form-item>
    <el-form-item v-if="editShow === false">
      <div class="detail-input-item">
        <div v-if="editType === 'tag'" class="tag">
          <el-tag class="tags" v-for="(tag, i) in initValue" :key="i">
            {{ tag }}
          </el-tag>
        </div>
        <div v-else-if="editType === 'formerNameTag'" class="tag">
          <el-tag class="tags" v-for="(tag, i) in initValue" :key="i">
            {{ tag }}
          </el-tag>
        </div>
        <div v-else-if="editType === 'select' && val !== ''">
          {{ val | format(dataOptions[property]) }}
        </div>
        <!-- <div v-else-if="editType === 'date'" class="data">
          {{ val }}
        </div> -->
        <div
          v-else-if="editType === 'cascade' && val.length > 0"
          class="data"
          style="display: flex"
        >
          <span v-for="(item, i) in val" :key="i" style="margin-right: 5px">
            {{ item.split(',')[1] }}
          </span>
        </div>
        <div
          v-else-if="editType === 'departmentCascade' && val.length > 0"
          class="data"
          style="display: flex"
        >
          <span v-for="(item, i) in val" :key="i" style="margin-right: 5px">
            {{ item.split(',')[1] }}
          </span>
        </div>
        <div
          v-else-if="editType === 'orgCascade' && val.length > 0"
          class="data"
          style="display: flex"
        >
          <!-- <span v-for="(item, i) in val" :key="i" style="margin-right:5px">
            {{ item.split(',')[0] }}
          </span> -->
          <span>{{ val[val.length - 1].split(',')[0] }}</span>
        </div>

        <div v-else-if="showType === 'link'">
          <el-link :underline="false" type="primary" @click="handleSuperiorOrg">{{ val }}</el-link>
        </div>

        <div
          v-else-if="val === '' || val === null || val.length < 1"
          style="width: 50px; height: 40px"
        ></div>
        <div v-else class="data">
          {{ val }}
        </div>
        <i
          v-if="editValid && auths !== 'read' && this.$route.query.type !== 'history'"
          class="detail-input-edit el-icon-edit"
          @click="editShow = true"
        ></i>
        <div>
          <div v-if="editType === 'select' && historyKey.includes(property)" class="history_trip">
            <el-tooltip placement="top">
              <div slot="content">
                <div>【当前版本】{{ currentData | format(dataOptions[property]) }}</div>
              </div>
              <img src="./img/history.svg" alt="" />
            </el-tooltip>
          </div>

          <div
            v-if="
              (editType === 'input' ||
                editType === 'textarea' ||
                editType === 'number' ||
                editType === '') &&
                historyKey.includes(property)
            "
            class="history_trip"
          >
            <el-tooltip placement="top">
              <div slot="content">
                <div>【当前版本】{{ currentData }}</div>
              </div>
              <img src="./img/history.svg" alt="" />
            </el-tooltip>
          </div>

          <div
            v-if="
              (editType === 'tag' || editType === 'formerNameTag') && historyKey.includes(property)
            "
            class="history_trip"
          >
            <el-tooltip placement="top">
              <div slot="content">
                <div>【当前版本】{{ currentData.join(',') }}</div>
              </div>
              <img src="./img/history.svg" alt="" />
            </el-tooltip>
          </div>

          <div v-if="editType === 'lenovo' && historyKey.includes(property)" class="history_trip">
            <el-tooltip placement="top">
              <div slot="content">
                <div>【当前版本】{{ currentData }}</div>
              </div>
              <img src="./img/history.svg" alt="" />
            </el-tooltip>
          </div>

          <div
            v-if="editType === 'orgCascade' && historyKey.includes(property)"
            class="history_trip"
          >
            <el-tooltip placement="top">
              <div slot="content">
                <div>【当前版本】{{ currentData.map(item => item.split(',')[0]).join('/') }}</div>
              </div>
              <img src="./img/history.svg" alt="" />
            </el-tooltip>
          </div>

          <div
            v-if="
              (editType === 'cascade' || editType === 'departmentCascade') &&
                historyKey.includes(property)
            "
            class="history_trip"
          >
            <el-tooltip placement="top">
              <div slot="content">
                <div>【当前版本】{{ currentData.map(item => item.split(',')[1]).join('/') }}</div>
              </div>
              <img src="./img/history.svg" alt="" />
            </el-tooltip>
          </div>
        </div>
      </div>
    </el-form-item>
    <el-form-item v-else>
      <div class="detail-input-form">
        <el-date-picker
          v-if="editType === 'date'"
          class="detail-input-form-input"
          v-model="val"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
        ></el-date-picker>
        <el-cascader
          v-if="editType === 'cascade'"
          class="detail-input-form-input"
          :props="addressProps"
          v-model="val"
        ></el-cascader>
        <el-cascader
          v-if="editType === 'departmentCascade' && switchShow"
          class="detail-input-form-input"
          :props="departmentProps"
          v-model="val"
        ></el-cascader>
        <el-cascader
          v-else-if="editType === 'orgCascade'"
          class="detail-input-form-input"
          :props="orgTypeProps"
          v-model="val"
        ></el-cascader>
        <el-input
          v-if="editType === 'input'"
          class="detail-input-form-input"
          v-model.trim="val"
          placeholder="请输入内容"
        ></el-input>

        <el-input
          v-if="editType === 'textarea'"
          type="textarea"
          class="detail-input-form-textarea"
          v-model.trim="val"
          placeholder="请输入内容"
        ></el-input>
        <el-select
          clearable
          class="detail-input-form-input"
          v-model="val"
          v-if="editType === 'lenovo' && property === 'hco_org'"
          filterable
          remote
          :remote-method="remoteMethod"
          :loading="remoteLoading"
          placeholder="请输入关键词"
        >
          <el-option
            v-for="(item, index) in hocOrgOptinos"
            :key="index"
            :label="item.label"
            :value="item.code"
          ></el-option>
        </el-select>

        <el-select
          clearable
          class="detail-input-form-input"
          v-model="val"
          v-if="editType === 'lenovo' && property === 'parent_org'"
          filterable
          remote
          :remote-method="remoteMethod2"
          :loading="remoteLoading2"
          placeholder="请输入关键词"
        >
          <el-option
            v-for="(item, index) in parentOrgOptions"
            :key="index"
            :label="item.label"
            :value="item.code"
          ></el-option>
        </el-select>

        <el-select
          clearable
          filterable
          v-if="editType === 'select'"
          class="detail-input-form-input"
          v-model="val"
          placeholder="请选择"
        >
          <el-option
            v-for="(item, index) in dataOptions[property]"
            :key="index"
            :label="item.label"
            :value="item.code"
          ></el-option>
        </el-select>

        <el-input-number
          v-if="editType === 'number'"
          class="detail-input-form-input"
          v-model="val"
          controls-position="right"
          :min="0"
        ></el-input-number>
        <div class="edit-btn">
          <i class="detail-input-form-icon el-icon-circle-check" @click="submit"></i>
          <i class="detail-input-form-icon el-icon-circle-close" @click="close"></i>
        </div>
      </div>
    </el-form-item>
  </div>
</template>
<script>
import {
  GetAdminDistrict,
  GetStandardDeptList,
  SearchMainDataByType,
  GetOrgTypeList,
} from '@/api/data';
export default {
  components: {},
  filters: {
    format(val, options = []) {
      for (const item of options) {
        if (Number(item.code) === Number(val) || item.code === val) {
          return item.label;
        }
      }
      return '无';
    },
  },
  props: {
    // 字段名称
    title: {
      type: String,
      default: '',
    },
    // 输入框的初始值
    initValue: {
      type: [String, Array, Number],
      default: '',
    },
    // 字段的对应属性名
    property: {
      type: String,
      default: '',
    },
    // 该字段是否可被编辑 不可编辑的字段不显示编辑入口
    editValid: {
      type: Boolean,
      default: false,
    },
    // 该字段使用的输入类型 分为输入框input和下拉框select
    editType: {
      type: String,
      default: 'input',
    },
    showType: {
      type: String,
      default: 'input',
    },
    // 额外定制 支持值map显示地图标志
    extra: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: Object,
      default: () => {},
    },
    auths: {
      type: String,
      default: '',
    },
    // 历史版本更新的字段
    historyKey: {
      type: Array,
      default: () => [],
    },
    // 当前版本数据
    currentData: {
      type: [String, Array, Number],
      default: '',
    },
  },
  data() {
    return {
      addressProps: {
        lazy: true,
        checkStrictly: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          const nodes = [];
          const req = {
            code: level === 0 ? '' : node.value.split(',')[0],
            level: level.toString(),
          };
          if (level > 4) {
            return;
          }
          GetAdminDistrict(req).then(res => {
            res.district_list.map(item => {
              const obj = {
                value: `${item.code},${item.name}`,
                label: item.name,
                leaf: !item.level,
              };
              nodes.push(obj);
            });
            // resolve 节点返回
            resolve(nodes);
          });
        },
      },
      departmentProps: {
        lazy: true,
        checkStrictly: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          const nodes = [];
          const req = {
            code: level === 0 ? '' : node.value.split(',')[0],
            level: level.toString(),
          };
          if (level > 2) {
            return;
          }
          GetStandardDeptList(req).then(res => {
            // console.log('科室', res);
            res.deps.map(item => {
              const obj = {
                value: `${item.code},${item.name}`,
                label: item.name,
                leaf: !item.level,
              };
              nodes.push(obj);
            });
            // resolve 节点返回
            resolve(nodes);
          });
        },
      },

      val: '',
      editShow: false,
      dataOptions: {},
      remoteLoading: false,
      remoteLoading2: false,
      hocOrgOptinos: [],
      parentOrgOptions: [],
      switchShow: true,
      data: {},
      current: '',
    };
  },
  computed: {
    orgTypeProps() {
      const first = this.dataOptions.firstType;
      if (!first) {
        return {};
      }
      this.orgTypeShow();
      return {
        lazy: true,
        checkStrictly: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          const nodes = [];
          const req = {
            parend_ids: level === 0 ? [first] : [node.value.split(',')[1]],
            level: level + 3,
          };
          if (level > 2) {
            return;
          }
          GetOrgTypeList(req).then(res => {
            console.log('机构类型', res);
            res.datas.map(item => {
              const obj = {
                value: `${item.type_name},${item.type_id}`,
                label: item.type_name,
                leaf: !item.is_leaf,
              };
              nodes.push(obj);
            });
            resolve(nodes);
          });
        },
      };
    },
  },
  mounted() {
    this.dataOptions = this.options;
    this.val = this.initValue;
    this.current = this.currentData;
  },
  methods: {
    orgTypeShow() {
      this.switchShow = false;
      this.$nextTick(() => {
        this.switchShow = true;
      });
    },
    remoteMethod(query) {
      if (query !== '') {
        this.remoteLoading = true;
        const req = {
          SearchOptions: [
            {
              data_name: 't_hco',
              field_name: 'name',
              type: 1,
              key: query,
            },
          ],
          index: 1,
          main_data: 't_hco',
          page_size: 20,
          search_fields: ['id', 'name'],
        };
        SearchMainDataByType(req).then(res => {
          this.hocOrgOptinos = res.data.map(item => {
            return {
              label: JSON.parse(item).name,
              code: `${JSON.parse(item).name},${JSON.parse(item).id}`,
            };
          });
          this.remoteLoading = false;
        });
      }
    },
    remoteMethod2(query) {
      if (query !== '') {
        this.remoteLoading2 = true;
        const org = {
          hospital: '医院',
          'hospital-ji': '基层医疗卫生机构',
          'hospital-pro': '专业公共医疗卫生机构',
          'hospital-other': '其他医疗卫生机构',
          dealer: '经销商',
          shop: '药店',
        };
        const req = {
          SearchOptions: [
            {
              data_name: 't_hco',
              field_name: 'name',
              type: 1,
              key: query,
            },
          ],
          data: org[this.$route.query.source],
          index: 1,
          main_data: 't_hco',
          page_size: 20,
          search_fields: ['id', 'name'],
        };
        SearchMainDataByType(req).then(res => {
          this.parentOrgOptions = res.data.map(item => {
            return {
              label: JSON.parse(item).name,
              code: `${JSON.parse(item).name},${JSON.parse(item).id}`,
            };
          });
          this.remoteLoading2 = false;
        });
      }
    },
    handleSelect(item) {
      console.log(item);
    },
    init() {},
    submit() {
      this.$emit('submit', this.property, { old: this.initValue, new: this.val });
      this.editShow = false;
    },
    handleSuperiorOrg() {
      this.$emit('handleSuperiorOrg', this.val);
    },
    close() {
      this.val = this.initValue;
      this.editShow = false;
    },
    // extra为map时支持的跳转到地图页面 39.892326,116.342763为经纬度
    gotoMap() {
      window.open(
        // 'https://apis.map.qq.com/uri/v1/marker?marker=coord:39.892326,116.342763;title:目标位置&referer=myapp',
        'https://lbs.qq.com/getPoint/',
      );
    },
  },
};
</script>
<style lang="scss">
@import './detail-input.scss';
.tag {
  display: flex;
  flex-wrap: wrap;
  // width: 200px;
  .tags {
    margin: 0 5px 5px 0;
  }
}
.data {
  max-width: 430px;
  min-height: 44px;
  line-height: 22px;
  display: flex;
  font-size: 14px;
  align-items: center;
}
.detail-input-form-textarea {
  width: 300px;
  height: 60px;
  .el-textarea__inner {
    background-color: #f2f3f8;
    color: #1c2028;
    border: none;
  }
}
.edit-btn {
  margin-left: 10px;
  display: flex;
}
</style>
